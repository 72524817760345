<template>
  <div>
    <div class="d-flex" style="column-gap: 20px">
      <div class="text-title mb-4 font-weight-semibold">
        {{ $t('common.attachedDocs', [documentsCount]) }}
      </div>
      <cz-button
        text
        small
        :icon-src="showDocs ? mdiEye : mdiEyeOff"
        outlined
        icon-color="primary"
        :title="showDocs ? $t('common.hide') : $t('common.show')"
        :icon-size="22"
        @click="showDocs = !showDocs"
      />
    </div>
    <v-row>
      <v-col
        :lg="4"
        :md="6"
        :sm="12"
        v-for="item in exisitingDocumentsToDisplay"
        :key="item._id"
      >
        <v-card
          class="d-flex align-center overflow-hidden"
          hover
          rounded="lg"
          outlined
          @click="viewDocument(item)"
        >
          <v-card-text class="pa-3">
            <div class="d-flex" style="column-gap: 10px">
              <cz-icon :size="44" :src="mdiFilePdfBox" color="primary" />
              <div class="d-flex flex-column overflow-hidden">
                <div
                  class="text-body-1 textPrimary--text text-truncate text-no-wrap"
                >
                  {{ item.title }}
                </div>
                <div
                  class="text-subtitle-2 font-weight-semibold textPrimary--text"
                >
                  {{ item.typeText }}
                </div>
                <div
                  class="textPrimary--text text-caption"
                  style="opacity: 0.5"
                >
                  {{
                    $t('common.documentUploadedAt', [
                      formatDateTimeToDisplay(item.createdAt)
                    ])
                  }}
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div class="text-title my-6 font-weight-semibold">
      {{ $t('common.additionalDocuments') }}
    </div>

    <div class="d-flex flex-column" style="gap: 15px" v-if="allowAddDocuments">
      <div
        class="d-flex align-center"
        style="gap: 15px"
        v-for="(item, index) in additionalDocuments"
        :key="`add_doc_file_${index}`"
      >
        <v-row dense>
          <v-col :lg="4">
            <cz-form-field :label="$t('common.additionalDocumentFile')">
              <cz-dropzone
                accept="application/pdf"
                @change="onAdditionalDocumentChange($event, index)"
                :height="55"
                :title="$t('dropzone.title')"
                :hide-title="!!item.file"
              >
                <div
                  slot="files"
                  class="py-2 dropzone-files overflow-hidden"
                  v-if="item.file"
                >
                  <cz-chip
                    v-if="item.file"
                    :text="item.file.name"
                    dark
                    removeable
                    circle-edges
                    small
                    @remove="item.file = null"
                    @click="openFile(item.file)"
                  />
                </div>
              </cz-dropzone>
            </cz-form-field>
          </v-col>
          <v-col :lg="4">
            <cz-form-field
              :label="$t('vendor.orderSubmissions.additionalDocumentTypeLabel')"
            >
              <validation-provider
                :rules="item.file ? 'required' : ''"
                :name="
                  $t('vendor.orderSubmissions.additionalDocumentTypeLabel')
                "
                v-slot="{ errors }"
              >
                <cz-autocomplete
                  v-model="item.type"
                  :items="documentTypes"
                  outlined
                  :error-messages="errors"
                  color="primary"
                  clearable
                  :placeholder="
                    $t(
                      'vendor.orderSubmissions.additionalDocumentTypePlaceholder'
                    )
                  "
                  :readonly="readonly"
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col :lg="4">
            <cz-form-field
              :label="$t('vendor.orderSubmissions.addtionalDocumentTitle')"
            >
              <validation-provider
                :rules="item.file ? 'required|max:30' : 'max:30'"
                :name="$t('vendor.orderSubmissions.addtionalDocumentTitle')"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="item.title"
                  outlined
                  :error-messages="errors"
                  color="primary"
                  clearable
                  :max-length="30"
                  :readonly="readonly"
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
        </v-row>
        <cz-button
          icon
          :icon-src="mdiTrashCanOutline"
          color="primary"
          large
          :icon-size="26"
          @click="onRemoveAdditionalDocument(index)"
        />
      </div>
      <cz-button
        :title="$t('vendor.orderSubmissions.addAddtionalDocument')"
        :icon-src="mdiPlus"
        color="primary"
        :width="120"
        @click="onAddDocument"
      />
    </div>
  </div>
</template>

<script>
import {
  mdiEyeOff,
  mdiEye,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiCloseCircleOutline,
  mdiTrashCanOutline,
  mdiFilePdfBox
} from '@mdi/js';
import { formatDateTimeToDisplay } from '@/shared/services/dates.service';
import {
  CzChip,
  CzIcon,
  CzButton,
  CzDropzone,
  CzFormField,
  CzAutocomplete,
  CzInput
} from '@/components';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
export default {
  name: 'AdditionalDocuments',
  components: {
    CzButton,
    CzFormField,
    CzDropzone,
    CzChip,
    CzAutocomplete,
    CzInput,
    CzIcon
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    allowAddDocuments: {
      type: Boolean,
      default: true
    },
    documentTypes: {
      type: Array,
      default: () => []
    },
    exisitingDocuments: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean
    }
  },
  computed: {
    documentsCount() {
      return this.exisitingDocuments?.length || 0;
    },
    exisitingDocumentsToDisplay() {
      return !this.showDocs ? [] : this.exisitingDocuments;
    }
  },
  created() {
    this.additionalDocuments = cloneDeep(this.value);
  },
  data() {
    return {
      mdiEyeOff,
      mdiTrashCanOutline,
      mdiFilePdfBox,
      mdiCloseCircleOutline,
      mdiPlus,
      mdiEye,
      mdiFileDocumentOutline,
      additionalDocuments: [],
      showDocs: false
    };
  },
  methods: {
    formatDateTimeToDisplay,
    viewDocument(item) {
      window.open(item.file.url, '_blank');
    },
    onAddDocument() {
      // add empty submission document to list
      this.additionalDocuments.push({
        type: '',
        title: '',
        file: null
      });
    },
    onAdditionalDocumentChange(files, index) {
      this.additionalDocuments[index].file = files[0];
    },
    onRemoveAdditionalDocument(index) {
      this.additionalDocuments.splice(index, 1);
    },
    getFileUrl(file) {
      if (file instanceof File) {
        return encodeURI(URL.createObjectURL(file));
      } else if (file?.url) {
        return file?.url;
      }

      return null;
    },
    openFile(file) {
      const url = this.getFileUrl(file);
      if (url) {
        window.open(url, '_blank');
      }
    }
  },
  watch: {
    value: {
      deep: true,
      handler(value) {
        if (!isEqual(value, this.additionalDocuments)) {
          this.additionalDocuments = cloneDeep(value);
        }
      }
    },
    additionalDocuments: {
      deep: true,
      handler(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
